<template>
    <admin-dashboard-layout>
        <v-row>
            <v-col class="text-right">
                <v-btn color="grey" :to="{name: 'admin.banks.create'}">Create</v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="banks"
            :items-per-page="15"
            class="elevation-1"
        >
            <template v-slot:item.name="{ item }">
                <v-row class="flex-column">
                    <v-col  class="cursor-pointer text--blue" @click="handleClickName(item)">
                        {{ item.name_hy }}
                    </v-col>
                    <v-col  class="cursor-pointer text--blue" @click="handleClickName(item)">
                        {{ item.name_ru }}
                    </v-col>
                    <v-col  class="cursor-pointer text--blue" @click="handleClickName(item)">
                        {{ item.name_en }}
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.logo_url="{ item }">
                <v-row class="cursor-pointer">
                    <v-col class="max-width200">
                        <img :src="`${item.logo_url}`" class="max-width100" />
                    </v-col>
                </v-row>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ new Date(item.created_at).toLocaleString() }} <!--TODO add day.js and default format -->
            </template>
            <template v-slot:item.actions="{ item }">
                <bank-delete-dialog @deleted="handleRemoveBank" :bank="item" />
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import Bank from '../../../models/Bank';
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import BankDeleteDialog from "../../../components/dialogs/BankDeleteDialog";

export default {
    name: "banks-index",
    components: {AdminDashboardLayout,BankDeleteDialog},
    data: function () {
        return {
            loading: false,
            banks: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Name', value: 'name'},
                {text: 'Logo', value: 'logo_url'},
                {text: 'Created at', value: 'created_at'},
                {text: 'Actions', value: 'actions'},
            ],
        }
    },
    methods: {
        handleClickName(bank) {
            this.$router.push({name: 'admin.banks.show', params: {id: bank.id}})
        },
        handleRemoveBank(id) {
            this.banks = this.$_.filter(this.banks, bank => bank.id !== id);
        }
    },
    async mounted() {
        this.loading = true
        this.banks = await Bank.get()
        this.loading = false
    },
}
</script>

<style lang="scss" scoped>
.max-width200 {
    max-width: 200px;
}

.max-width100 {
    max-width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.text--blue {
    color: #0d47a1;
}

.text--blue:hover {
    text-decoration: underline;
}
</style>
